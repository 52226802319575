import React from "react"
import { NavItem, NavLink, UncontrolledDropdown, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, Media, Badge } from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import * as Icon from "react-feather"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { Button } from "../../../components/custom/button/Button"
import GetModule from "./GetModule"

// a little function to help us with reordering the bookmarks
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

class NavbarBookmarks extends React.PureComponent {
  state = {
    showBookmarks: false,
    value: "",
    noSuggestions: false,
    isStarred: false,
    suggestions: [],
    starredItems: [],
  }

  updateBookmarks = false

  handleBookmarksVisibility = () => {
    this.setState({
      showBookmarks: !this.state.showBookmarks,
      value: "",
      suggestions: [],
      noSuggestions: false,
      starred: null,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bookmarks.starred.length !== this.state.starredItems.length && this.updateBookmarks === true) {
      this.setState({ starredItems: this.props.bookmarks.starred })
      this.updateBookmarks = false
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const starredItems = reorder(this.state.starredItems, result.source.index, result.destination.index)

    this.setState({
      starredItems,
    })
  }

  renderBookmarks = () => {
    this.updateBookmarks = true
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="d-flex flex-sm-wrap flex-lg-nowrap draggable-cards">
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  render() {
    const iconAction = [
      {
        icon: "fas fa-cube",
        //onClick: () => testAlert(),
        color: "#626262",
        iconSize: 20,
      },
    ]

    const modulesList = [
      {
        name: "บริหารจัดการรหัสมาตรฐานกลาง",
        icon: "fas fa-home",
        navLink: "/settings/nameprefixes",
        detail: [],
      },
      {
        name: "จัดการสิทธิ์การใช้งานระบบ",
        icon: "fas fa-user",
        navLink: "/settingprivilege",
        detail: [
          {
            name: "ตั้งค่ากลุ่มสิทธิ์",
          },
          {
            name: "ตั้งค่าผู้ใช้งาน",
          },
        ],
      },
      {
        name: "Monitoring",
        icon: "fas fa-book-open",
        navLink: "/sessionlist",
        detail: [
          {
            name: "Session",
          },
          {
            name: "Access Logs",
          },
          {
            name: "Activity Logs",
          },
        ],
      },
    ]

    let { sidebarVisibility } = this.props
    return (
      <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
        <ul className="nav navbar-nav d-xl-none">
          <NavItem className="mobile-menu mr-auto">
            <NavLink className="nav-menu-main menu-toggle hidden-xs is-active" onClick={sidebarVisibility}>
              <Icon.Menu className="ficon" />
            </NavLink>
          </NavItem>
          <UncontrolledDropdown tag="li" className="dropdown-notification nav-item">
            {/* <DropdownToggle tag="a" className="nav-link nav-link-label">
              <Button type="icon" className="d-flex align-items-center" iconAction={iconAction} />
            </DropdownToggle> */}
            <DropdownMenu tag="ul" right className="dropdown-menu-media pt-0 mt-1">
              <li className="dropdown-menu-header">
                <div className="dropdown-header mt-0">
                  <h4 className="text-white">Main Menu</h4>
                </div>
              </li>
              <PerfectScrollbar
                className="media-list overflow-hidden position-relative"
                options={{
                  wheelPropagation: false,
                }}
              >
                <GetModule></GetModule>
              </PerfectScrollbar>
              <li className="dropdown-menu-footer">
                <DropdownItem tag="a" className="p-1 text-center">
                  <span className="align-middle">Bangkok of Education Management Information Systen</span>
                </DropdownItem>
              </li>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ul>
        <ul className="nav navbar-nav bookmark-icons">
          <GetModule></GetModule>

        </ul>
      </div>
    )
  }
}

export default NavbarBookmarks
