import React from 'react'
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, Media } from 'reactstrap'
import { useLocation } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Button } from '../../../components/custom/button/Button'
import { Spinner } from '../../../components/custom/spinner/Spinner'
import _ from 'lodash'
import styled from 'styled-components'

export const PerfectscrollbarStyled = styled.span`
  .custom-perfectscrollbar {
    overflow: auto !important;
    .ps__rail-x,
    .ps__rail-y {
      display: none !important;
      .ps__thumb-y {
        display: none !important;
      }
    }
  }

  .custom-perfectscrollbar::-webkit-scrollbar {
    width: 8px;
    background: #fff;
  }

  /* Track */
  .custom-perfectscrollbar::-webkit-scrollbar-track:hover {
    background: #ddd;
  }

  /* Handle */
  .custom-perfectscrollbar::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 20px;
  }

  /* Handle on hover */
  .custom-perfectscrollbar::-webkit-scrollbar-thumb:hover {
    background: #888;
  }

  .media {
    .media-left {
      padding-right: 0.8rem;
      .icon {
        width: 25px;
        text-align: center;
      }
    }
  }
`

const GetModule = () => {
  const MODULES = JSON.parse(localStorage.getItem('MODULES'))
  let activePathArr = useLocation().pathname.split('/')

  let schoolName = 'สำนักการศึกษา'
  if (localStorage.getItem('USER_TYPE') === '1') {
    schoolName = localStorage.getItem('USER_ORGANIZATION_NAME')
  } else if (localStorage.getItem('USER_TYPE') === '2' || localStorage.getItem('USER_TYPE') === '3') {
    schoolName = 'โรงเรียน' + localStorage.getItem('USER_SCHOOL_NAME')
  } else {
    schoolName = 'สำนักการศึกษา'
  }

  function handleClick(path) {
    window.location = path
  }

  const findCurrentMenuCode = (pathname, modules) => {
    if (modules) {
      const matchedModules = modules.find(module => {
        const subModulesAfterFilter = module.level1.find(subModule => {
          if (subModule.level2.length > 0) {
            const subModuleLv2AfterFilter = subModule.level2.find(subModuleLv2 => subModuleLv2.menuPath === pathname)

            if (subModuleLv2AfterFilter) {
              return true
            }
          }

          return subModule.menuPath === pathname
        })

        if (subModulesAfterFilter) {
          return true
        }
      })

      if (matchedModules) {
        return matchedModules.menuCode
      }
    }
    return null
  }

  let currentMenuCode = null

  while (activePathArr.length > 2) {
    const activePath = activePathArr.join('/')
    currentMenuCode = findCurrentMenuCode(activePath, MODULES)
    if (currentMenuCode != null) {
      break
    } else {
      activePathArr.pop()
    }
  }

  const getModules = () => {
    return (
      <>
        {!MODULES ? (
          <div className="d-flex justify-content-center my-3">
            <Spinner color="primary" />
          </div>
        ) : (
          MODULES.map(
            (item, key) =>
              item.firstRoleMenu && (
                <div
                  className={`d-flex justify-content-between body-list-modules ${
                    currentMenuCode === item.menuCode ? 'active' : ''
                  }`}
                  key={key}
                  onClick={() => handleClick(item.firstRoleMenu.menuPath)}
                >
                  <Media className="d-flex align-items-start">
                    <Media left>
                      {item.icon ? (
                        <div className="icon">
                          <i className={item.icon} style={{ fontSize: 20, color: '#267659' }} />
                        </div>
                      ) : null}
                    </Media>

                    <Media body>
                      <Media heading className="media-heading mb-0" style={{ color: '#267659' }} tag="h5">
                        {item.menuNameTH}
                      </Media>
                    </Media>
                  </Media>
                </div>
              ),
          )
        )}
      </>
    )
  }
  return (
    <>
      <p
        className="d-none d-sm-block my-0 ml-1 align-self-center"
        style={{
          border: '1px solid #267659',
          boxSizing: 'border-box',
          borderRadius: '6px',
          padding: '8px 12px',
          color: '#267659',
        }}
      >
        {schoolName}
      </p>
      <UncontrolledDropdown tag="li" className="dropdown-notification nav-item d-lg-block">
        <DropdownToggle tag="a" className="nav-link nav-link-label pt-0 pb-0 pl-0 pr-0 ml-1">
          <Button type="primary" height="40" width="150">
            เมนูหลัก
          </Button>
        </DropdownToggle>
        <DropdownMenu tag="ul" className="dropdown-menu-media dropdown-menu-left pt-0 mt-1">
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              <h4 className="text-white">เมนูหลัก</h4>
            </div>
          </li>
          <PerfectscrollbarStyled>
            <PerfectScrollbar
              className="media-list overflow-hidden position-relative custom-perfectscrollbar"
              options={{
                wheelPropagation: false,
              }}
            >
              {getModules()}
            </PerfectScrollbar>
          </PerfectscrollbarStyled>
        </DropdownMenu>
      </UncontrolledDropdown>
      {process.env?.REACT_APP_ENV && ['local', 'dev', 'uat'].includes(process.env?.REACT_APP_ENV) ? (
        <p
          className="d-none d-sm-block my-0 ml-1 align-self-center"
          style={{
            border: '0px solid #267659',
            boxSizing: 'border-box',
            borderRadius: '6px',
            padding: '8px 2px',
            color: '#dc3545',
            fontSize: '1.2em',
          }}
        >
          ({process.env.REACT_APP_ENV.toUpperCase()})
        </p>
      ) : null}
    </>
  )
}

export default GetModule
