import React from 'react'
import { ButtonDefault, ButtonIcon } from './ButtonStyled'
import { Theme } from '../../../styles'
export const Button = props => {
  // console.log("props.iconAction",props.iconAction)
  //console.log(props.iconData[0].onClick);
  return (
    <>
      {props.type === 'primary' ? (
        <ButtonDefault
          {...props}
          textColor={Theme.Color_SeaGreen2}
          activeColor={Theme.Color_SeaGreen2}
          borderColor={Theme.Color_SeaGreen2}
        >
          {props.icon && (
            <div style={{ fontSize: props.iconSize ? props.iconSize : 16 }} className="mr-1 textActive textColor">
              <i className={props.icon} />
            </div>
          )}
          <div className="textActive textColor">{props.children}</div>
        </ButtonDefault>
      ) : props.type === 'danger' ? (
        <ButtonDefault
          {...props}
          textColor={Theme.Color_Error6}
          activeColor={Theme.Color_Error6}
          borderColor={Theme.Color_Error6}
        >
          {props.icon && (
            <div style={{ fontSize: props.iconSize ? props.iconSize : 16 }} className="mr-1 textActive textColor">
              <i className={props.icon} />
            </div>
          )}
          <div className="textActive textColor">{props.children}</div>
        </ButtonDefault>
      ) : props.type === 'back' ? (
        <ButtonDefault
          {...props}
          textColor={Theme.Color_Warning5}
          activeColor={Theme.Color_Warning5}
          borderColor={Theme.Color_Warning5}
        >
          {props.icon && (
            <div style={{ fontSize: props.iconSize ? props.iconSize : 16 }} className="mr-1 textActive textColor">
              <i className={props.icon} />
            </div>
          )}
          <div className="textActive textColor">{props.children}</div>
        </ButtonDefault>
      ) : props.type === 'disabled' ? (
        <ButtonDefault
          {...props}
          disabled={true}
          activeColor={'#BDBDBD'}
          borderColor={'#BDBDBD'}
          style={{ cursor: 'default' }}
        >
          {props.icon && (
            <div style={{ fontSize: props.iconSize ? props.iconSize : 16 }} className="mr-1 iconColor">
              <i className={props.icon} />
            </div>
          )}
          <div className="textActive textColor">{props.children}</div>
        </ButtonDefault>
      ) : props.type === 'color' ? (
        <ButtonDefault
          {...props}
          activeColor={Theme.Color_Warning5}
          borderColor={props.bgColor ? props.bgColor : Theme.Color_Warning5}
        >
          {props.icon && (
            <div style={{ fontSize: props.iconSize ? props.iconSize : 16 }} className="mr-1 textActive iconColor">
              <i className={props.icon} />
            </div>
          )}
          <div className="textActive textColor">{props.children}</div>
        </ButtonDefault>
      ) : props.type === 'icon' ? (
        props.iconAction ? (
          props.iconAction.map((item, key) => (
            <ButtonIcon {...props} color={item.color} onClick={item.onClick} key={key}>
              {item.icon ? (
                <div style={{ fontSize: item.iconSize ? item.iconSize : 16 }}>
                  <i className={item.icon} />
                </div>
              ) : null}
            </ButtonIcon>
          ))
        ) : (
          <ButtonIcon {...props} color={props.color} onClick={props.onClick}>
            {props.icon ? (
              <div style={{ fontSize: props.iconSize ? props.iconSize : 16 }}>
                <i className={props.icon} />
              </div>
            ) : null}
          </ButtonIcon>
        )
      ) : null}
      {/* ) : (
         <ButtonStrap {...props} className="colorRed">
           {props.children}
         </ButtonStrap>
      ) */}
    </>
  )
}
