import React, { useState, createContext, useContext, useEffect } from 'react'
import { gql, useLazyQuery, useQuery } from '@apollo/client'

const AuthContext = createContext()
const useAuth = () => useContext(AuthContext)

const GET_MODULES = gql`
  query getModules($menuParentCode: String!, $systemCode: String!, $userCode: Float!) {
    getModules: newGetModules(menuParentCode: $menuParentCode, systemCode: $systemCode, userCode: $userCode) {
      menuCode
      menuNameTH
      menuParentCode
      icon
      systemCode
      canView
      action
      firstRoleMenu: firstRow {
        ...menuInfo
      }
      level1: children {
        ...menuInfo
        level2: children {
          ...menuInfo
        }
      }
    }
  }

  fragment menuInfo on SystemUserRoles {
    menuCode
    menuNameTH
    menuParentCode
    menuPath
    canView
    action
  }
`

const AuthProvider = ({ children }) => {
  const [data, setData] = useState()
  const USERPROF = localStorage.getItem('USERPROF')
  const userAuth = USERPROF ? true : false

  /* //comment เพราะมีใน LoginSchool.jsx แล้ว
  const [getModules, { loading: loadModules, data: dataModules }] = useLazyQuery(GET_MODULES)

  useEffect(
    () => {
      if (USERPROF) {
        const getUser = JSON.parse(USERPROF)
        console.log('getUser?.userCode: ', getUser?.userCode)
        if (getUser?.userCode) {
          getModules({
            variables: {
              userCode: getUser?.userCode,
              systemCode: '01',
              menuParentCode: '0000',
            },
          })
        }
      }
    },
    [USERPROF],
  )

  useEffect(
    () => {
      if (dataModules) {
        localStorage.setItem('MODULES', JSON.stringify(dataModules.getModules))
      }
    },
    [dataModules],
  )
    */

  const login = input => {
    return new Promise((resolve, reject) => {
      localStorage.setItem('USERPROF', JSON.stringify(input.decryptData))
      localStorage.setItem('USER_EMAIL', input.decryptData.email)
      localStorage.setItem('USER_FNAME', input.decryptData.firstname ? input.decryptData.firstname : '')
      localStorage.setItem('USER_LNAME', input.decryptData.lastname ? input.decryptData.lastname : '')
      localStorage.setItem('USER_CODE', input.decryptData.userCode)
      localStorage.setItem('USER_SCHOOL_CODE', input.decryptData.schoolCode)
      localStorage.setItem('USER_ORGANIZATION_CODE', input.decryptData.organizationCode)
      localStorage.setItem('USER_PERSONNEL_CODE', input.decryptData.schoolPersonnelCode)
      localStorage.setItem('USER_ORGANIZATION_NAME', input.decryptData.organizationNameTH)
      localStorage.setItem('USER_SCHOOL_NAME', input.decryptData.schoolName)
      localStorage.setItem('USER_TYPE', input.decryptData.userType)

      resolve(input)
    })
  }

  const logout = () => {
    return new Promise((resolve, reject) => {
      try {
        localStorage.clear()
        resolve({ status: true })
      } catch (e) {
        reject(e)
      }
    })
  }

  return <AuthContext.Provider value={{ data, login, logout, checkAuth: userAuth }}>{children}</AuthContext.Provider>
}

export { AuthProvider, useAuth }
