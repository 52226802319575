import styled from 'styled-components'
import { Theme } from '../../../styles'
export const ButtonDefault = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //padding: 0px 0px 0px 17px;
  //position: absolute;

  background-color: ${props => (props.outline ? '#ffffff' : props.borderColor)} !important;

  width: ${props => (props.width ? props.width + 'px' : '100%')};
  height: ${props => (props.height ? props.height : '44')}px;
  left: 312px;
  top: 209px;

  /* Color/Primary-0 */

  border: 1px solid ${props => (props.borderColor ? props.borderColor : props.textColor)};
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .iconColor {
    color: ${props =>
      props.outline
        ? props.textColor
        : props.type == 'color'
        ? props.iconColor
          ? props.iconColor
          : props.textColor
        : '#ffffff'};
  }
  .textColor {
    color: ${props => (props.outline ? props.textColor : props.type === 'color' ? props.textColor : '#ffffff')};
    //font-weight: 300;
    font-size: ${props => (props.fontSize ? props.fontSize : '14')}px;
    line-height: 20px;
  }
  transition: 300ms;
  &:hover {
    background-color: ${props => (props.outline ? 'rgba(233, 236, 239, 0.5)' : props.borderColor)} !important;
  }
  &:active {
    border: 1px solid ${props => (props.outline || props.disabled ? props.activeColor : '#e9ecef')};
    background-color: ${props =>
      props.outline || props.disabled
        ? props.activeColor
          ? props.activeColor
          : props.textColor
        : '#e9ecef'} !important;
    transition: 200ms;
    text-decoration: none;
    .textActive {
      color: ${props => (props.textActiveColor ? props.textActiveColor : '#ffffff')};
      transition: 200ms;
    }
  }
`
export const ButtonIcon = styled.div`
  margin: 0px 5px 0px 2px;
  height: 100%;
  cursor: pointer;
  //border-width: 0.5px;
  color: ${props => (props.color ? props.color : Theme.Color_DarkNavy4)};
  &:active {
    color: #6e84a3;
  }
`
