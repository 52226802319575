import React from "react"
import { Progress as ProgressStrap } from "reactstrap"
import "../../../assets/scss/custom-css/custom.scss"
export const Progress = ({ children, size, value, color, mAuto }) => {
  return (
    <ProgressStrap className={(size ? `progress-${size}` : "progress-lg", mAuto ? "marginProgressCustom" : "")} value={value ? value : "0"} color={color ? color : "primary"}>
      {children}
    </ProgressStrap>
  )
}
