import styled from "styled-components"

export const DivPositionFixed = styled.div`
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2000;

  .content-loading {
    --widthlogoloading: 80px;
    position: relative;
    top: calc(50% - var(--widthlogoloading));
    margin: auto;
    width: var(--widthlogoloading);
    height: var(--widthlogoloading);
    pointer-events: none;
    .loading-text {
      margin-top: 4px;
      text-align: center;
      font-size: 14px;
      line-height: 1;
    }
  }
`

export const DivPositionFixedProgress = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2000;

  .content-progress-animated {
    --widthlogoloading: 370px;
    position: relative;
    top: calc(95% - var(--widthlogoloading));
    margin: auto;
    width: var(--widthlogoloading);
    height: var(--widthlogoloading);
    pointer-events: none;
    .loading-text {
      // margin-top: 4px;
      text-align: center;
      font-size: 14px;
      // line-height: 1;
    }
  }
`

export const DivPositionFixedNoBg = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2000;

  .content-loading {
    --widthlogoloading: 80px;
    position: relative;
    top: calc(50% - var(--widthlogoloading));
    margin: auto;
    width: var(--widthlogoloading);
    height: var(--widthlogoloading);
    pointer-events: none;
    .loading-text {
      margin-top: 4px;
      text-align: center;
      font-size: 14px;
      line-height: 1;
    }
  }
`
