import React, { useState } from "react"
import { Theme } from "../../../styles"
import { Card, Progress as ProgressAnimated } from "reactstrap"
import { Eclipse, Spinner as Spinners, Rolling, DualRing, Ball, Spin } from "react-loading-io"
import { DivPositionFixed, DivPositionFixedProgress } from "./PopupLoadingStyled.jsx"
import { Progress as ProgressLoad } from "../progress/Progress.jsx"
import "../../../assets/scss/custom-css/custom.scss"
export const PopupLoading = (props) => {
  //const [progress, setProgress] = useState(0)
  let progress = 0
  if (props.prog) {
    const full = props.full
    const prog = props.prog
    progress = (prog * 100) / full
    // console.log(progress)
  }
  return (
    <DivPositionFixed>
      <div className="content-loading">
        <Rolling size={64} width={10} color={Theme.Color_SeaGreen2} />
        <div className="loading-text">
          {(() => {
            if (typeof props.text === "undefined") {
              return "Loading"
            } else if (props.text) {
              return props.text
            }
          })()}
        </div>
        <div style={{ width: "200px" }}>{props.prog ? <ProgressLoad size="lg" value={progress} color="primary"></ProgressLoad> : ""}</div>
      </div>
    </DivPositionFixed>
  )
}

export const ProgressLoading = (props) => {
  let progress = 0
  if (props.prog) {
    const full = props.full
    const prog = props.prog
    progress = (prog * 100) / full
    // console.log(progress)
  }
  return (
    <DivPositionFixed>
      <div className="content-progress">
        <Card className="card-progress">
          {props.prog ? (
            <ProgressLoad mAuto={"m-auto"} size="lg" value={progress} color="primary" className="m-auto">
              Loading...
            </ProgressLoad>
          ) : (
            ""
          )}
        </Card>
      </div>
    </DivPositionFixed>
  )
}

export const ProgressLoadingCenter = (props) => {
  let progress = 0
  if (props.prog) {
    const full = props.full
    const prog = props.prog
    progress = (prog * 100) / full
    // console.log(progress)
  }
  return (
    <DivPositionFixedProgress>
      <div className="content-progress-animated">
          <div className="row no-gutters align-items-center" style={{backgroundColor: Theme.Color_LightNavy5 , width: '400px', height: '70px' , borderRadius: 10}}>
                <div className="col-12 p-1">
                    <div className="text-center">Loading {progress}%</div>
                    <div>
                    <ProgressAnimated animated color="primary" value={progress} style={{ height: '15px' }} />
                    </div>
                </div>
          </div>
      </div>
    </DivPositionFixedProgress>
  )
}
