import React from "react"
import * as Icon from "react-feather"

const horizontalMenuConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/",
  },
  {
    id: "sapImport",
    title: "SAP Imports",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/page2",
  },
  {
    id: "allComponent",
    title: "All Components",
    type: "item",
    icon: <Icon.Archive size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/allComponents",
  },
  {
    id: "CRUD",
    title: "CRUD",
    type: "item",
    icon: <Icon.File size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/crud",
  },
]

export default horizontalMenuConfig
